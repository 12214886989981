import request from '@/utils/request'

// 获取监测设备列表
export function listDevice(params) {
  if(params){
    params.pageNum = 1
    params.pageSize=10000
  }
  return request({
    url: '/demo/monitor/device/list',
    method: 'get',
    params
  })
}

//获取大气监测记录
export function listAirRecord(params) {
  if(params){
    params.pageNum = 1
    params.pageSize=1000000
  }
  return request({
    url: '/demo/monitor/air/list',
    method: 'get',
    params
  }) 
}

//地表水监测记录
export function listWaterRecord(params) {
  if(params){
    params.pageNum = 1
    params.pageSize=1000000
  }
  return request({
    url: '/demo/monitor/water/list',
    method: 'get',
    params
  })
}