
//顶部导航栏高
export const TopNavHeight = 80;

//顶部导航高
export const BottomNavHeight = 0;

//内容区高
export function contentHeight() {
    /*
    获取浏览器可见区域(不包括标题栏、地址栏、收藏夹栏状态栏等额外区域，仅为页面呈现区域)的高度和宽度
    宽度：document.documentElement.clientWidth
    高度：document.documentElement.clientHeight
    */
    return document.documentElement.clientHeight - (TopNavHeight + BottomNavHeight) + 'px';
}

export function topNavHeight() {
    return TopNavHeight + 'px';
}

export function bottomNavHeight() {
    return BottomNavHeight + 'px';
}

export function setElementHeight(id, heightCallback) {
    document.getElementById(id).style.height = heightCallback();
}