<template>
<div id="footer">
    <div id="up">
        <div class="section">
            <span class="title">走进绿桥</span>
            <a href="./intro.html">公司介绍</a>
            <a href="./intro.html?son=lyc">公司资质</a>
            <a href="./about.html">联系我们</a>
        </div>
        <div class="section">
            <span class="title">服务范围</span>
            <a href="./casees.html">公司案例</a>
            <a href="./news.html">新闻中心</a>
            <a href="https://lqhb.com.cn/admin" target="_blank">环保管家</a>
        </div>
        <div class="section">
            <!-- <el-image style="" fit="cover" src="https://lqhb-1301820126.cos.ap-chengdu.myqcloud.com/20211129/f6575408a64b43e89153bf13f10ec3df.png"></el-image> -->
            <span class="title">联系我们</span>
            <a>地址：贵阳市观山湖诚信路富力中心A3栋</a>
            <a>电话：0851-84725486</a>
            <a>邮箱：914288078@qq.com</a>
        </div>
        <div class="qrcode">
            <div>
                <el-image style="width:100px;height:100px" src="https://lqhb-1301820126.cos.ap-chengdu.myqcloud.com/20211130/6cd4e95362de4461ba647a12eff043e2.png" />
                <span>服务号</span>

            </div>
            <div>
                <el-image style="width:100px;height:100px" src="https://lqhb-1301820126.cos.ap-chengdu.myqcloud.com/20211130/5519c895772c4f80816c3b65a7b72c43.png" />
                <span>订阅号</span>
            </div>
        </div>
    </div>
    <div id="down">
        <div>
            友情链接:<a href="https://www.mee.gov.cn/" target="_blank">国家生态环境部</a> <a href="http://sthj.guizhou.gov.cn/" target="_blank">贵州生态环境厅</a><a href="http://sthjj.guiyang.gov.cn/" target="_blank">贵阳市生态环境局</a>
        </div>
        <div class="beian">
            ©2021 贵州绿桥环保科技有限公司 <a target="_blank" style="margin: 0px 5px 0px 15px;line-height:20px;color:#939393;" href="https://beian.miit.gov.cn">黔ICP备2021008426号-1</a>
            <div style="width:300px;margin:0 auto; padding:10px 0;">
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52011502001687" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                    <img src="https://lqhb-1301820126.cos.ap-chengdu.myqcloud.com/20220112/e1311f816f294c308393aadb73ebda5a.png" style="float:left;" />
                    <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">贵公网安备 52011502001687号</p>
                </a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {}
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
#footer {
    margin-top: 30px;

    #up {
        display: flex;
        justify-content: space-evenly;
        color: #606060;

        .section {
            display: flex;
            flex-direction: column;
            letter-spacing: 2px;

            .title {
                font-weight: bolder;
                color: black;
            }

            a {
                margin-top: 5px;
                text-decoration: none;
                color: #606060;
            }
        }

        .qrcode {
            display: flex;

            div {
                margin-right: 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    #down {
        margin: 30px 0px 60px 0px;
        padding-top: 30px;
        border-top: 1px solid rgb(221, 221, 221);
        display: flex;
        justify-content: space-evenly;
        color: rgb(175, 175, 175);
        font-size: 13px;

        a {
            margin-left: 20px;
            color: rgb(175, 175, 175);
            text-decoration: none;
        }

        .beian{
            display: flex;
            align-items: center;
        }
    }
}
</style>
