<template>
<div class="section">
    <span class="chinese">{{chinese}}</span><span class="english">{{english}}</span>
</div>
</template>

<script>
export default {
    props: {
        chinese: {
            type: String,
            default: null
        },
        english: {
            type: String,
            default: null
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.section {
    border-left: 4px solid #029F95;
    border-radius: 2.5px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin: 0px 0px 0px 0px;

    .chinese {
        font-size: 25px;
        color: black;
        font-weight: bold;
    }

    .english {
        margin-left: 8px;
        font-size: 18px;
        color: #222A41;
    }
}
</style>
