function getQueryVariable(variable) {
    const uri = decodeURIComponent(window.location.search)
    var query = uri.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        // alert(pair)
        if (pair[0] == variable) { return pair[1]; }
    }
    return undefined;
}

export default  getQueryVariable