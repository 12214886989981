const start = `<span style='color: red'>`;
const end = `</span>`;

//高亮搜索关键字
export function highlightKeywords(keywords, searchResults) {
    if (!keywords) return searchResults;

    let str = JSON.stringify(searchResults);
    const redKeyword = `${start}${keywords}${end}`
    str = str.replaceAll(keywords, redKeyword);
    const data = JSON.parse(str);
    return data;
}

export function removeHighlight(target) {
    if (!target) return "";
    const isString = (typeof target == 'string');
    if (isString) {
        return target.replaceAll(start, "").replaceAll(end, "");
    } else {
        const isObject = (typeof target == 'object');
        if (isObject) {
            let str = JSON.stringify(target);
            str = str.replaceAll(start, "").replaceAll(end, "");
            return JSON.parse(str);
        } else {
            return "";
        }
    }
}