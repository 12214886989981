<template>
<div id="top-nav">
    <div class="logo">
        <div class="icon">
            <el-image :src="logoUrl" />
        </div>
    </div>
    <div id="menu">
        <div :class="{'selected-indicator':inHome}" class="menu-item"><a href="./index.html">首页</a></div>
        <div :class="{'selected-indicator':inIntro}" class="menu-item"><a href="./intro.html">走进绿桥</a></div>
        <div :class="{'selected-indicator':inCasees}" class="menu-item"><a href="./casees.html">公司案例</a></div>
        <div :class="{'selected-indicator':inNews}" class="menu-item"><a href="./news.html">新闻中心</a></div>
        <div :class="{'selected-indicator':inNotice}" class="menu-item"><a href="./notice.html">环保公示</a></div>
        <div :class="{'selected-indicator':inGj}" class="menu-item"><a href="https://lqhb.com.cn/admin" target="_blank">环保管家</a></div>
        <div :class="{'selected-indicator':inAbout}" class="menu-item"><a href="./about.html">联系我们</a></div>
    </div>
</div>
</template>

<script>
import logoUrl from "../assets/logo.png";
import {
    topNavHeight,
    setElementHeight
} from "@/css/size.js";

export default {
    name: "top-nav",
    props: ['useIn'],
    data() {
        return {
            logoUrl: logoUrl
        }
    },
    computed: {
        inHome: function () {
            return this.useIn == "home";
        },
        inIntro: function () {
            return this.useIn == "intro";
        },
        inCasees: function () {
            return this.useIn == "casees";
        },
        inNews: function () {
            return this.useIn == "news";
        },
        inNotice: function () {
            return this.useIn == "notice";
        },
        inGj: function () {
            return this.useIn == "gj";
        },
        inAbout: function () {
            return this.useIn == "about";
        }

    },
    mounted() {
        setElementHeight('top-nav', topNavHeight);
        this.setWidth();
        window.onresize = ()=>{
            this.setWidth();
        }
    },
    methods: {
        setWidth() {
            const max = 1920;
            const width = document.documentElement.clientWidth;
            document.getElementById("top-nav").style.width = Math.min(max, width) + 'px';
        }
    }
}
</script>

<style lang="scss" scoped>
#top-nav {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 40px;
    background: white;
    font-family: PingFangSC-Regular, sans-serif;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 3px 0px rgba(233, 233, 233, 0.7);
}

.logo {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
        // max-width: 140px;
        // width: 140px;
        // max-height: 43px;
        // height: 43px;
    }
}

#menu {
    height: 100%;
    display: flex;
    align-items: center;

    .menu-item {
        height: 100%;
        margin: 0px 30px;
        font-size: 18px;
        display: flex;
        align-items: center;
        color: black;
        letter-spacing: 2px;
    }

    $color: rgba(3, 159, 148, 1);

    .menu-item:hover {
        color: $color;
        font-weight: bold;
    }

    .selected-indicator {
        border-bottom: 4px solid $color;
        border-radius: 2px;
        color: $color;
        font-weight: bold;
    }
}

a {
    text-decoration: none;
    color: inherit;
}
</style>
