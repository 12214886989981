<template>
<div id="leave-message">
    <el-tooltip class="item" effect="dark" content="我们将尽快安排专员为您服务" placement="left">
        <div id="form" v-loading="loading">
            <el-form ref="form" :rules="rules" :model="form" label-position="left" label-width="60px">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="form.name" placeholder="请输入您的姓名" size="small"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="mobile">
                    <el-input v-model="form.mobile" placeholder="请输入联系电话" size="small"></el-input>
                </el-form-item>
                <!-- <el-form-item label="邮箱" prop="email">
                    <el-input v-model="form.email" placeholder="请输入邮箱" size="small"></el-input>
                </el-form-item> -->
                <el-form-item label="内容" prop="content">
                    <el-input type="textarea" rows="3" v-model="form.content" placeholder="咨询内容"></el-input>
                </el-form-item>
            </el-form>
            <div id="submit-button" @click="submit">提交</div>
        </div>
    </el-tooltip>

</div>
</template>

<script>
import {
    addFeedback
} from "@/api/feedback.js";

function isMobile(rule, value, callback) {
    const reg = /^1[3456789]\d{9}$/;
    const isPhone = reg.test(value);
    isPhone ? callback() : callback(new Error("请输入正确的手机号"));
}

export default {
    data() {
        return {
            loading: false,
            form: {
                name: undefined,
                mobile: undefined,
                email: undefined,
                content: undefined
            },
            rules: {
                name: [{
                    required: true,
                    message: "请输入姓名",
                    trigger: "blur"
                }, {
                    min: 2,
                    max: 6,
                    message: '姓名长度为2到5个字符',
                    trigger: "blur"
                }],
                mobile: [{
                    required: true,
                    message: "请输入手机号",
                    trigger: "blur"
                }, {
                    validator: isMobile,
                    trigger: "blur"
                }],
                content: [{
                    required: true,
                    message: "请输入咨询内容",
                    trigger: "blur"
                }, {
                    min: 6,
                    max: 200,
                    message: '长度为6到200个字符',
                    trigger: "blur"

                }]
            }
        }
    },
    mounted() {

    },
    methods: {
        submit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    addFeedback(this.form).then(() => {
                        this.form = {}
                        this.$message({duration:8000, showClose: false, message: "我们已收到您的留言，将尽快安排业务专员为您服务,请耐心等待，谢谢！", type: "success" });
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
#leave-message {
    height: 458px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-image: url("https://lqhb-1301820126.cos.ap-chengdu.myqcloud.com/20211129/7fe56671634f425a868ac089e925bea8.png");
    background-size: cover;

    #form {
        padding: 20px 15px;
        margin-right: 60px;
        width: 300px;
        border-radius: 4px;
        box-shadow: 0px 0px 3px 5px rgba(128, 128, 128, 0.062);
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;

        #submit-button {
            width: 270px;
            padding: 7px 0px;
            margin-top: 30px;
            text-align: center;
            background-color: #029F95;
            color: white;
        }

        #submit-button:hover {
            cursor: pointer;
            background-color: #04dbcd;
        }
    }
}
</style>
